var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table',{ref:"refInvoiceListTable",staticClass:"text-center promos-table",attrs:{"items":_vm.promoShown,"responsive":"","fields":_vm.tableColumns,"primary-key":"id","show-empty":"","empty-text":"No hay promociones"},scopedSlots:_vm._u([{key:"cell(promo_type)",fn:function(data){return [_c('div',[_c('b-badge',{attrs:{"pill":"","variant":data.value === 'free_product'
            ? 'light-success'
            : data.value === 'price_discount'
            ? 'light-warning'
            : 'primary'}},[_vm._v(" "+_vm._s(_vm._f("promoType")(data.item.promo_type))+" - "),(data.item.promo_type === 'free_product')?_c('span',[_vm._v(" "+_vm._s(data.item.gets_you)+" "+_vm._s("unidad gratis")+" ")]):(data.item.promo_type === 'price_discount')?_c('span',[_vm._v(" "+_vm._s(data.item.discount)+" "+_vm._s("% de descuento")+" ")]):(data.item.promo_type === 'reward_points')?_c('span',[_vm._v(" "+_vm._s(data.item.reward_points)+" "+_vm._s("% en puntos")+" ")]):(data.item.promo_type === 'promo_code')?_c('span',[_vm._v(" "+_vm._s(data.item.discount)+" "+_vm._s("% de descuento")+" ")]):_vm._e()])],1)]}},{key:"cell(valid_at)",fn:function(data){return [_c('p',{staticClass:"text-nowrap mr-2"},[_vm._v(" Desde: "+_vm._s(_vm._f("fullDate")(data.item.begins_at))+" ")]),_c('p',{staticClass:"text-nowrap mr-2"},[_vm._v("Hasta: "+_vm._s(_vm._f("fullDate")(data.item.ends_at)))])]}},{key:"cell(ends_at)",fn:function(data){return [_c('span',{staticClass:"text-nowrap mr-2"},[_vm._v(" "+_vm._s(_vm._f("fullDate")(data.value))+" ")])]}},{key:"cell(needs_to_buy)",fn:function(data){return [[_c('b-badge',{attrs:{"pill":"","variant":data.item.promo_type === 'free_product'
            ? 'light-success'
            : data.item.promo_type === 'price_discount'
            ? 'light-warning'
            : 'primary'}},[_vm._v(" "+_vm._s(data.item.needs_to_buy)+" "+_vm._s("unidades")+" ")])]]}},{key:"cell(promo_code)",fn:function(data){return [[(data.item.promo_code)?_c('b-badge',{attrs:{"pill":"","variant":data.item.promo_type === 'free_product'
            ? 'light-success'
            : data.item.promo_type === 'price_discount'
            ? 'light-warning'
            : 'primary'}},[_vm._v(" "+_vm._s(data.item.promo_code)+" ")]):_vm._e()]]}},{key:"cell(status)",fn:function(data){return [[(
          data.item.is_published === false && data.item.is_canceled === false
        )?_c('b-button',{attrs:{"variant":'success'},on:{"click":function($event){return _vm.publishPromo(data.item.id)}}},[_vm._v(" Activar ")]):(
          data.item.is_published === true && data.item.is_canceled === false
        )?_c('b-button',{attrs:{"variant":'danger'},on:{"click":function($event){return _vm.cancelPromo(data.item.id)}}},[_vm._v(" Cancelar ")]):_vm._e()]]}},{key:"cell(actions)",fn:function(data){return [_c('b-dropdown',{attrs:{"variant":"link","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.notifyPromo(data.item.id)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"BellIcon","size":"16"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Notificar")])],1)],1)]}}])},[_vm._v("' ")])}
var staticRenderFns = []

export { render, staticRenderFns }