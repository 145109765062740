<template>
  <div class="py-1">
    <validation-observer ref="accountRules" tag="form">
      <div class="mb-2">
        <p class="mb-50">Tipo de promoción</p>

        <validation-provider
          #default="{ errors }"
          name="Tipo de promoción"
          rules="required"
        >
          <v-select
            v-model="promo.promo_type"
            :options="[
              { id: 'price_discount', name: 'Descuento' },
              { id: 'free_product', name: 'Producto gratis' },
              { id: 'reward_points', name: 'Puntos de recompensa' },
            ]"
            label="name"
            placeholder="Selecciona una promoción"
            :reduce="(option) => option.id"
            :searchable="true"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </div>

      <div class="mb-2">
        <div v-if="promo.promo_type === 'free_product'">
          <div class="mb-1">
            <h5 class="mb-0">Configuración de la promoción</h5>
            <small class="text-muted">
              Ingresa las cantidades adecuadas para tu promoción. Si eliges que
              cuando te compran 3 unidades y regalas 1, estarás regalándole una
              unidad del producto a tu cliente
            </small>
          </div>

          <b-form-group
            label="Cantidad mínima para que aplique la promoción"
            label-for="needs_to_buy"
          >
            <validation-provider
              #default="{ errors }"
              name="Cantidad mínima para que aplique la promoción"
              rules="required"
            >
              <b-form-input
                id="needs_to_buy"
                v-model="promo.needs_to_buy"
                placeholder="1"
                :state="errors.length > 0 ? false : null"
                size="lg"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Regalas" label-for="last-name">
            <validation-provider
              #default="{ errors }"
              name="Regalas"
              rules="required"
            >
              <b-form-input
                id="first-name"
                v-model="promo.gets_you"
                placeholder="2"
                :state="errors.length > 0 ? false : null"
                size="lg"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>

        <div v-if="promo.promo_type === 'price_discount'">
          <div class="mb-1">
            <h5 class="mb-0">Configuración General</h5>
            <small class="text-muted"
              >Ingresa la cantidad mínima para que se aplique la promoción y el
              porcentage de descuento</small
            >
          </div>
          <b-form-group
            label="Cantidad mínima para que aplique la promoción"
            label-for="needs_to_buy"
          >
            <validation-provider
              #default="{ errors }"
              name="Cantidad mínima para que aplique la promoción"
              rules="required"
            >
              <b-form-input
                id="needs_to_buy"
                v-model="promo.needs_to_buy"
                placeholder="1"
                :state="errors.length > 0 ? false : null"
                size="lg"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Descuento %" label-for="price_discount">
            <validation-provider
              #default="{ errors }"
              name="Descuento"
              rules="required"
            >
              <b-form-input
                id="price_discount"
                v-model="promo.discount"
                placeholder="10%"
                :state="errors.length > 0 ? false : null"
                size="lg"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>

        <div v-if="promo.promo_type === 'reward_points'">
          <div cols="12" class="mb-1">
            <h5 class="mb-0">Configuración General</h5>
            <small class="text-muted"
              >Ingresa la cantidad mínima para que se aplique la promoción y el
              porcentage de puntos de recompens que otorgarás. Si el precio de
              un producto es $10.00 MXN y das el 10% de recompensa, les estarás
              regalando $1.00 MXN en puntos
            </small>
          </div>
          <b-form-group
            label="Cantidad mínima para que aplique la promoción"
            label-for="needs_to_buy"
          >
            <validation-provider
              #default="{ errors }"
              name="Cantidad mínima para que aplique la promoción"
              rules="required"
            >
              <b-form-input
                id="needs_to_buy"
                v-model="promo.needs_to_buy"
                placeholder="1"
                :state="errors.length > 0 ? false : null"
                size="lg"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Puntos de recompensa %"
            label-for="reward_points"
          >
            <validation-provider
              #default="{ errors }"
              name="Reward points"
              rules="required"
            >
              <b-form-input
                id="reward_points"
                v-model="promo.reward_points"
                placeholder="10%"
                :state="errors.length > 0 ? false : null"
                size="lg"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
      </div>

      <div>
        <div cols="12" class="mb-50">
          <p class="mb-0">Fecha valida de promoción</p>
        </div>
        <b-form-group label="Fecha inicial" label-for="begins_at">
          <validation-provider
            #default="{ errors }"
            name="Fecha inicial"
            rules="required"
          >
            <b-form-input
              type="date"
              id="begins_at"
              v-model="promo.begins_at"
              placeholder="1"
              :state="errors.length > 0 ? false : null"
              size="lg"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label="Fecha final" label-for="ends_at">
          <validation-provider
            #default="{ errors }"
            name="Fecha final"
            rules="required"
          >
            <b-form-input
              type="date"
              id="ends_at"
              v-model="promo.ends_at"
              placeholder="10%"
              :state="errors.length > 0 ? false : null"
              size="lg"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </div>

      <div class="pt-2">
        <b-button @click="formSubmitted" variant="primary" block>
          Crear promoción
        </b-button>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import vSelect from "vue-select"
import { mapActions } from "vuex"
import { ValidationProvider, ValidationObserver } from "vee-validate"

import messagesMixin from "@core/mixins/messagesMixin"
import { handleAPIErrors } from "@/@core/utils/fetch-utils"

export default {
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [messagesMixin],
  data() {
    return {
      promo: {
        begins_at: "",
        ends_at: "",
        promo_type: "",
        needs_to_buy: 2,
        gets_you: 1,
        discount: 0,
        reward_points: 0,
        store_id: this.$route.params.store_id,
        store_product_ids: [],
      },
    }
  },
  methods: {
    ...mapActions("promotions", ["createPromotion"]),
    formSubmitted() {
      this.validationForm()
        .then(() => {
          this.promo.needs_to_buy = +this.promo.needs_to_buy
          this.promo.gets_you = +this.promo.gets_you
          this.promo.store_product_ids = [this.$route.params.store_product_id]

          this.createPromotion(this.promo)
            .then(() => {
              this.toastSuccess({
                title: "Promoción creada",
                text: "La promoción ha sido creada exitosamente",
              })
              this.$emit("promotionCreated")
            })
            .catch((error) => {
              const errors = handleAPIErrors(error.response.data)
              this.toastError({
                title: "Error",
                text: errors.join(", "),
              })
            })
        })
        .catch(() => {
          this.toastError({
            title: "Error",
            text: "Por favor, llena los campos requeridos",
          })
        })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
