var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-1"},[_c('validation-observer',{ref:"accountRules",attrs:{"tag":"form"}},[_c('div',{staticClass:"mb-2"},[_c('p',{staticClass:"mb-50"},[_vm._v("Tipo de promoción")]),_c('validation-provider',{attrs:{"name":"Tipo de promoción","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":[
            { id: 'price_discount', name: 'Descuento' },
            { id: 'free_product', name: 'Producto gratis' },
            { id: 'reward_points', name: 'Puntos de recompensa' } ],"label":"name","placeholder":"Selecciona una promoción","reduce":function (option) { return option.id; },"searchable":true},model:{value:(_vm.promo.promo_type),callback:function ($$v) {_vm.$set(_vm.promo, "promo_type", $$v)},expression:"promo.promo_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"mb-2"},[(_vm.promo.promo_type === 'free_product')?_c('div',[_c('div',{staticClass:"mb-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Configuración de la promoción")]),_c('small',{staticClass:"text-muted"},[_vm._v(" Ingresa las cantidades adecuadas para tu promoción. Si eliges que cuando te compran 3 unidades y regalas 1, estarás regalándole una unidad del producto a tu cliente ")])]),_c('b-form-group',{attrs:{"label":"Cantidad mínima para que aplique la promoción","label-for":"needs_to_buy"}},[_c('validation-provider',{attrs:{"name":"Cantidad mínima para que aplique la promoción","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"needs_to_buy","placeholder":"1","state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.promo.needs_to_buy),callback:function ($$v) {_vm.$set(_vm.promo, "needs_to_buy", $$v)},expression:"promo.needs_to_buy"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2506482304)})],1),_c('b-form-group',{attrs:{"label":"Regalas","label-for":"last-name"}},[_c('validation-provider',{attrs:{"name":"Regalas","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"first-name","placeholder":"2","state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.promo.gets_you),callback:function ($$v) {_vm.$set(_vm.promo, "gets_you", $$v)},expression:"promo.gets_you"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1586250954)})],1)],1):_vm._e(),(_vm.promo.promo_type === 'price_discount')?_c('div',[_c('div',{staticClass:"mb-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Configuración General")]),_c('small',{staticClass:"text-muted"},[_vm._v("Ingresa la cantidad mínima para que se aplique la promoción y el porcentage de descuento")])]),_c('b-form-group',{attrs:{"label":"Cantidad mínima para que aplique la promoción","label-for":"needs_to_buy"}},[_c('validation-provider',{attrs:{"name":"Cantidad mínima para que aplique la promoción","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"needs_to_buy","placeholder":"1","state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.promo.needs_to_buy),callback:function ($$v) {_vm.$set(_vm.promo, "needs_to_buy", $$v)},expression:"promo.needs_to_buy"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2506482304)})],1),_c('b-form-group',{attrs:{"label":"Descuento %","label-for":"price_discount"}},[_c('validation-provider',{attrs:{"name":"Descuento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"price_discount","placeholder":"10%","state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.promo.discount),callback:function ($$v) {_vm.$set(_vm.promo, "discount", $$v)},expression:"promo.discount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,581223687)})],1)],1):_vm._e(),(_vm.promo.promo_type === 'reward_points')?_c('div',[_c('div',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v("Configuración General")]),_c('small',{staticClass:"text-muted"},[_vm._v("Ingresa la cantidad mínima para que se aplique la promoción y el porcentage de puntos de recompens que otorgarás. Si el precio de un producto es $10.00 MXN y das el 10% de recompensa, les estarás regalando $1.00 MXN en puntos ")])]),_c('b-form-group',{attrs:{"label":"Cantidad mínima para que aplique la promoción","label-for":"needs_to_buy"}},[_c('validation-provider',{attrs:{"name":"Cantidad mínima para que aplique la promoción","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"needs_to_buy","placeholder":"1","state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.promo.needs_to_buy),callback:function ($$v) {_vm.$set(_vm.promo, "needs_to_buy", $$v)},expression:"promo.needs_to_buy"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2506482304)})],1),_c('b-form-group',{attrs:{"label":"Puntos de recompensa %","label-for":"reward_points"}},[_c('validation-provider',{attrs:{"name":"Reward points","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"reward_points","placeholder":"10%","state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.promo.reward_points),callback:function ($$v) {_vm.$set(_vm.promo, "reward_points", $$v)},expression:"promo.reward_points"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2700970165)})],1)],1):_vm._e()]),_c('div',[_c('div',{staticClass:"mb-50",attrs:{"cols":"12"}},[_c('p',{staticClass:"mb-0"},[_vm._v("Fecha valida de promoción")])]),_c('b-form-group',{attrs:{"label":"Fecha inicial","label-for":"begins_at"}},[_c('validation-provider',{attrs:{"name":"Fecha inicial","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"date","id":"begins_at","placeholder":"1","state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.promo.begins_at),callback:function ($$v) {_vm.$set(_vm.promo, "begins_at", $$v)},expression:"promo.begins_at"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Fecha final","label-for":"ends_at"}},[_c('validation-provider',{attrs:{"name":"Fecha final","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"date","id":"ends_at","placeholder":"10%","state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.promo.ends_at),callback:function ($$v) {_vm.$set(_vm.promo, "ends_at", $$v)},expression:"promo.ends_at"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"pt-2"},[_c('b-button',{attrs:{"variant":"primary","block":""},on:{"click":_vm.formSubmitted}},[_vm._v(" Crear promoción ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }