var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.formSubmitted.apply(null, arguments)}}},[_c('validation-observer',{ref:"accountRules",attrs:{"tag":"form"}},[_c('div',[_c('div',{staticClass:"mb-50"},[_c('p',{staticClass:"mb-0"},[_vm._v("Tipo de promoción")]),_c('small',{staticClass:"text-muted"},[_vm._v(" Selecciona la promoción deseada ")])]),_c('validation-provider',{attrs:{"name":"Tipo de promoción","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":[
              { label: 'Código promocional', value: 'price_discount' } ],"label":"label","clearable":false,"searchable":false,"placeholder":"Selecciona una promoción","size":"lg","reduce":function (option) { return option.value; }},model:{value:(_vm.promo.promo_type),callback:function ($$v) {_vm.$set(_vm.promo, "promo_type", $$v)},expression:"promo.promo_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"mt-2"},[(_vm.promo.promo_type === 'price_discount')?_c('div',[_c('div',{staticClass:"mb-50",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v("Configuración General")]),_c('small',{staticClass:"text-muted"},[_vm._v(" Ingresa la cantidad mínima para que se aplique la promoción y el porcentage de descuento ")])]),_c('div',[_c('b-form-group',{attrs:{"label":"Código promocional","label-for":"promo_code"}},[_c('validation-provider',{attrs:{"name":"Código promocional","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"promo_code","placeholder":"HSKJAL","state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.promo.promo_code),callback:function ($$v) {_vm.$set(_vm.promo, "promo_code", $$v)},expression:"promo.promo_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,533601094)})],1)],1),_c('div',[_c('b-form-group',{attrs:{"label":"Descuento %","label-for":"price_discount"}},[_c('validation-provider',{attrs:{"name":"Descuento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"price_discount","placeholder":"10%","state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.promo.discount),callback:function ($$v) {_vm.$set(_vm.promo, "discount", $$v)},expression:"promo.discount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,581223687)})],1)],1)]):_vm._e()]),(_vm.promo.promo_type === 'price_discount')?_c('div',{staticClass:"mt-2"},[_c('div',{staticClass:"mb-50"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Fecha valida de promoción")]),_c('small',{staticClass:"text-muted"},[_vm._v(" Ingresa las fechas para las cuales tu promoción estará vigente ")])]),_c('div',[_c('b-form-group',{attrs:{"label":"Fecha inicial","label-for":"begins_at"}},[_c('validation-provider',{attrs:{"name":"Fecha inicial","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"date","id":"begins_at","placeholder":"1","state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.promo.begins_at),callback:function ($$v) {_vm.$set(_vm.promo, "begins_at", $$v)},expression:"promo.begins_at"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2899785210)})],1)],1),_c('div',[_c('b-form-group',{attrs:{"label":"Fecha final","label-for":"ends_at"}},[_c('validation-provider',{attrs:{"name":"Fecha final","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"date","id":"ends_at","placeholder":"10%","state":errors.length > 0 ? false : null,"size":"lg"},model:{value:(_vm.promo.ends_at),callback:function ($$v) {_vm.$set(_vm.promo, "ends_at", $$v)},expression:"promo.ends_at"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1698689359)})],1)],1)]):_vm._e()]),(_vm.promo.promo_type)?_c('b-button',{staticClass:"mt-3 mb-1",attrs:{"type":"submit","variant":"primary","block":""}},[_vm._v(" Crear promoción ")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }