<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="m-2">
      <b-row class="d-flex align-items-center justify-content-between">
        <b-col
          cols="12"
          sm="auto"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <h3>Promos</h3>
        </b-col>
        <b-button
          v-if="$route.params.store_product_id != null"
          variant="primary"
          v-b-modal="`add-promo`"
        >
          <feather-icon icon="PlusIcon" />
          Agregar promoción
        </b-button>
        <b-button
          v-if="$route.params.store_product_id == null"
          variant="primary"
          v-b-modal="`add-promo-code`"
        >
          <feather-icon icon="PlusIcon" />
          Agregar código promocional
        </b-button>
      </b-row>
    </div>

    <b-modal ref="addPromoModal" id="add-promo" hide-footer>
      <template v-slot:modal-title>
        <div>Agregar promoción</div>
      </template>
      <promos-new-wizard @promotionCreated="closeModal" />
    </b-modal>

    <b-modal ref="addPromoModal" id="add-promo-code" hide-footer size="lg">
      <template v-slot:modal-title>
        <div>Agregar promoción</div>
      </template>
      <promos-new-wizard-for-promotion-code
        @promoCodeCreated="closeModalPromoCode"
      />
    </b-modal>

    <div v-if="loading" class="px-2">
      <b-skeleton height="400px"></b-skeleton>
    </div>

    <div v-else>
      <promos-table
        :loading="loading"
        :promoShown="promoShown"
        :cancelPromo="cancelPromo"
        :publishPromo="publishPromo"
      />

      <promos-list
        :loading="loading"
        :promoShown="promoShown"
        :cancelPromo="cancelPromo"
        :publishPromo="publishPromo"
        :handlePromoModal="handlePromoModal"
      />
    </div>

    <promos-modal :selectedPromo="selectedPromo" />

    <!-- Footer: Pagination -->
    <div class="mx-2 mb-2 mt-1">
      <pagination
        :loading="loading"
        :handlePagination="handlePagination"
        :pagination="pagination"
        :entriesPerPage.sync="entriesPerPage"
      />
    </div>
  </b-card>
</template>

<script>
import vSelect from "vue-select"
import { mapActions, mapGetters } from "vuex"
import { ValidationObserver } from "vee-validate"

import PromosNewWizard from "@/@core/components/promos/PromosNewWizard.vue"
import PromosNewWizardForPromotionCode from "@/@core/components/promos/PromosNewWizardForPromotionCode.vue"
import Pagination from "@/@core/components/Pagination.vue"
import PromosTable from "@/@core/components/promos/PromosTable.vue"
import PromosList from "@/@core/components/promos/PromosList.vue"
import PromosModal from "@/@core/components/promos/PromosModal.vue"

import router from "@/router"
import { required } from "@validations"

export default {
  components: {
    vSelect,
    ValidationObserver,

    PromosNewWizard,
    PromosNewWizardForPromotionCode,
    Pagination,
    PromosTable,
    PromosList,
    PromosModal,
  },
  props: {
    dates: {
      type: [Array, String],
      default: () => null,
    },
  },
  data() {
    return {
      required,
      promoShown: null,
      loading: false,
      selectedPromo: null,
      entriesPerPage: "10",
      promotion: {
        id: null,
      },
    }
  },
  computed: {
    ...mapGetters("promotions", ["pagination", "promotions"]),
  },
  watch: {
    entriesPerPage() {
      this.getPromotions({}, { page: this.pagination.page })
    },
  },

  beforeMount() {
    this.getPromotions()
  },

  mounted() {
    this.promoShown = this.promotions
  },
  methods: {
    ...mapActions("promotions", [
      "fetchPromotions",
      "publishPromotion",
      "cancelPromotion",
    ]),

    handlePromoModal(promo) {
      this.selectedPromo = promo
      this.$bvModal.show("promos-modal")
    },

    closeModal() {
      this.$bvModal.hide("add-promo")

      this.getPromotions()
    },

    closeModalPromoCode() {
      this.$bvModal.hide("add-promo-code")

      this.getPromotions()
    },

    handlePagination({ page, per_page }) {
      this.getPromotions({}, { page, per_page })
    },

    publishPromo(promotionId) {
      this.promotion.id = promotionId
      this.publishPromotion(this.promotion).then((response) => {
        this.getPromotions()
      })
    },

    cancelPromo(promotionId) {
      this.promotion.id = promotionId
      this.cancelPromotion(this.promotion).then((response) => {
        this.getPromotions()
      })
    },

    getPromotions(filters, { page = 1 } = {}) {
      this.loading = true

      const store_product_id = router.currentRoute.params.store_product_id
      const store_id = router.currentRoute.params.store_id

      this.fetchPromotions({
        ...filters,
        by_store: store_id,
        by_product: store_product_id,
        by_is_canceled: false,
        by_is_applicable: "today",
        meta: {
          pagination: {
            page,
            per_page: this.entriesPerPage,
          },
        },
      })
        .then(() => {
          this.promoShown = this.promotions
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss">
.media {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-grow: 1;
}
</style>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.search-input {
  max-width: 200px;
}
</style>

<style lang="scss">
.promos-table {
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
}
.promos-list {
  display: block;

  @media (min-width: 768px) {
    display: none;
  }
}
</style>
