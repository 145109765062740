<template>
  <b-modal id="promos-modal" title="Promociones" size="lg" ok-only>
    <div v-if="selectedPromo" class="p-50">
      <div v-if="selectedPromo.store_products">
        <p class="text-break bold" v-if="selectedPromo.store_products[0]">
          {{ selectedPromo.store_products[0].product_attributes.name }}
        </p>
        <p class="text-break bold" v-else>
          {{ "Todos los productos" }}
        </p>

        <p
          v-if="selectedPromo.store_products[0]"
          class="font-weight-bold d-block text-break"
        >
          {{ selectedPromo.store_products[0].product_attributes.name }}
        </p>
        <p
          v-if="!selectedPromo.store_products[0]"
          class="font-weight-bold d-block text-break"
        >
          {{ selectedPromo.product_name }}
        </p>
      </div>

      <div>
        <small class="text-muted"
          >{{ selectedPromo.begins_at | fullDate }} -</small
        >
        <small class="text-muted">{{
          selectedPromo.end_date | fullDate
        }}</small>
      </div>

      <div class="d-flex flex-wrap mt-1">
        <span> Codigo promocional:</span>
        <promo-badge :selectedPromo="selectedPromo">
          {{ selectedPromo.promo_code }}
        </promo-badge>
      </div>

      <!-- DESCUENTO -->
      <div class="d-flex flex-wrap mt-1">
        <span>Tipo de promocion:</span>
        <promo-badge :selectedPromo="selectedPromo">
          {{ selectedPromo.promo_type | promoType }} -
          <span v-if="selectedPromo.promo_type === 'free_product'">
            {{ selectedPromo.gets_you }} {{ "unidad gratis" }}
          </span>
          <!-- badge for price discount promotion-->
          <span v-else-if="selectedPromo.promo_type === 'price_discount'">
            {{ selectedPromo.discount }} {{ "% de descuento" }}
          </span>
          <!-- badge for reward points promotion-->
          <span v-else-if="selectedPromo.promo_type === 'reward_points'">
            {{ selectedPromo.reward_points }} {{ "% en puntos" }}
          </span>
          <span v-else-if="selectedPromo.promo_type === 'promo_code'">
            {{ selectedPromo.discount }} {{ "% de descuento" }}
          </span>
        </promo-badge>
      </div>

      <div class="d-flex flex-wrap mt-1">
        <span>Compra minima: </span>
        <promo-badge :selectedPromo="selectedPromo">
          {{ selectedPromo.needs_to_buy }} {{ "unidades" }}
        </promo-badge>
      </div>

      <div class="mt-2">
        <b-button
          :variant="'success'"
          size="sm"
          @click.stop="notifyPromo(selectedPromo.id)"
        >
          Notificar
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions } from "vuex"

import { handleAPIErrors } from "@/utils/fetch-utils"
import messagesMixin from "@core/mixins/messagesMixin"

import PromoBadge from "./PromoBadge.vue"

export default {
  components: {
    PromoBadge,
  },
  mixins: [messagesMixin],
  props: {
    selectedPromo: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    ...mapActions("messaging", ["notifyPromotionViaWhatsApp"]),

    async notifyPromo(id) {
      try {
        await this.notifyPromotionViaWhatsApp({
          promotion_id: id,
          store_product_id: this.$route.params.store_product_id,
        })
        this.toastSuccess({
          title: "Notificación",
          text: "Promoción notificada exitosamente",
        })
      } catch (error) {
        const errors = handleAPIErrors(error.response.data)
        this.toastError({
          title: "Error",
          text: errors.join(", "),
        })
      }
    },
  },
}
</script>
