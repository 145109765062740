<template>
  <div>
    <form @submit.prevent="formSubmitted" ref="form">
      <validation-observer ref="accountRules" tag="form">
        <div>
          <div class="mb-50">
            <p class="mb-0">Tipo de promoción</p>
            <small class="text-muted"> Selecciona la promoción deseada </small>
          </div>
          <validation-provider
            #default="{ errors }"
            name="Tipo de promoción"
            rules="required"
          >
            <v-select
              v-model="promo.promo_type"
              :options="[
                { label: 'Código promocional', value: 'price_discount' },
              ]"
              label="label"
              :clearable="false"
              :searchable="false"
              placeholder="Selecciona una promoción"
              size="lg"
              :reduce="(option) => option.value"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </div>

        <div class="mt-2">
          <div v-if="promo.promo_type === 'price_discount'">
            <div cols="12" class="mb-50">
              <h5 class="mb-0">Configuración General</h5>
              <small class="text-muted">
                Ingresa la cantidad mínima para que se aplique la promoción y el
                porcentage de descuento
              </small>
            </div>
            <div>
              <b-form-group label="Código promocional" label-for="promo_code">
                <validation-provider
                  #default="{ errors }"
                  name="Código promocional"
                  rules="required"
                >
                  <b-form-input
                    id="promo_code"
                    v-model="promo.promo_code"
                    placeholder="HSKJAL"
                    :state="errors.length > 0 ? false : null"
                    size="lg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div>
              <b-form-group label="Descuento %" label-for="price_discount">
                <validation-provider
                  #default="{ errors }"
                  name="Descuento"
                  rules="required"
                >
                  <b-form-input
                    id="price_discount"
                    v-model="promo.discount"
                    placeholder="10%"
                    :state="errors.length > 0 ? false : null"
                    size="lg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </div>
        </div>

        <div class="mt-2" v-if="promo.promo_type === 'price_discount'">
          <div class="mb-50">
            <h5 class="mb-0">Fecha valida de promoción</h5>
            <small class="text-muted">
              Ingresa las fechas para las cuales tu promoción estará vigente
            </small>
          </div>
          <div>
            <b-form-group label="Fecha inicial" label-for="begins_at">
              <validation-provider
                #default="{ errors }"
                name="Fecha inicial"
                rules="required"
              >
                <b-form-input
                  type="date"
                  id="begins_at"
                  v-model="promo.begins_at"
                  placeholder="1"
                  :state="errors.length > 0 ? false : null"
                  size="lg"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div>
            <b-form-group label="Fecha final" label-for="ends_at">
              <validation-provider
                #default="{ errors }"
                name="Fecha final"
                rules="required"
              >
                <b-form-input
                  type="date"
                  id="ends_at"
                  v-model="promo.ends_at"
                  placeholder="10%"
                  :state="errors.length > 0 ? false : null"
                  size="lg"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
        </div>
      </validation-observer>

      <b-button
        v-if="promo.promo_type"
        type="submit"
        variant="primary"
        class="mt-3 mb-1"
        block
      >
        Crear promoción
      </b-button>
    </form>
  </div>
</template>

<script>
import vSelect from "vue-select"
import Cleave from "vue-cleave-component"
import { mapActions } from "vuex"
import { QrcodeStream } from "vue-qrcode-reader"
import { FormWizard, TabContent } from "vue-form-wizard"
import { ValidationProvider, ValidationObserver } from "vee-validate"

import BaseCropper from "@/@core/components/BaseCropper.vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

import messagesMixin from "@core/mixins/messagesMixin"
import { handleAPIErrors } from "@/utils/fetch-utils"

import "@/@core/scss/vue/libs/vue-wizard.scss"
import "vue-form-wizard/dist/vue-form-wizard.min.css"

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    vSelect,
    Cleave,
    ToastificationContent,
    BaseCropper,
    QrcodeStream,
  },
  mixins: [messagesMixin],
  data() {
    return {
      promo: {
        begins_at: "",
        ends_at: "",
        promo_type: "",
        needs_to_buy: 1,
        gets_you: 1,
        discount: null,
        reward_points: null,
        promo_code: "",
        store_id: this.$route.params.id,
        store_product_ids: [],
      },
    }
  },
  methods: {
    ...mapActions("promotions", ["createPromotion"]),
    async formSubmitted() {
      try {
        await this.validationForm()
      } catch {
        return
      }

      try {
        const res = await this.createPromotion(this.promo)
        this.toastSuccess({
          title: "Promoción creada",
          text: "La promoción ha sido creada con éxito",
        })
        this.$emit("promoCodeCreated")

        this.$refs.form.reset()
      } catch (e) {
        const errors = handleAPIErrors(e.response.data)

        if (errors.length) {
          this.toastError({
            title: "Error",
            text: errors.join(", "),
          })
        } else {
          this.toastError({
            title: "Error",
            text: "Ha ocurrido un error al crear la promoción",
          })
        }
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
